import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "../CompanyInfo/companyInfo.css";
import CountUp from "react-countup";

const CompanyInfo = () => {
  const compantData = [
    {
      countNo: "20+",
      title: "Happy clients",
    },
    {
      countNo: "40+",
      title: "Finished projects",
    },
    {
      countNo: "36+",
      title: "Skilled Experts",
    },
    {
      countNo: "200+",
      title: "Git Repos",
    },
  ];
  return (
    <>
      <div className="main_info">
        <Container>
          <Row>
            {compantData?.map((item, index) => {
              const numericValue = parseInt(item?.countNo, 10);
              return (
                <>
                  <Col lg={3}>
                    <div className="inner-content">
                      <div className="fw-bold fs-2">
                        <CountUp start={0} end={numericValue} duration={5} />+
                      </div>
                      <p className="fs-4">{item?.title} </p>
                    </div>
                    {index !== compantData.length -1 && (
                      <div className="section_border"> </div>
                    )}
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
      </div>
    </>
  );
};
export default CompanyInfo;
