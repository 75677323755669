import React from "react";
import {images, aboutService} from "../../assets";
import "../About/about.css";
import {Button, Col, Container, Image, Row} from "react-bootstrap";
import Loading from "../Common/Loading";
import Header from "../Header/Header";
import Footer from "../Footer/index";

const About = () => {
	const aboutServiceData = [
		{
			icon: aboutService.service1,
			title: "UX/UI Designs",
		},
		{
			icon: aboutService.service2,
			title: "Web Base Applications",
		},
		{
			icon: aboutService.service3,
			title: "Desktop Applications",
		},
		{
			icon: aboutService.service4,
			title: "Native Mobile Applications",
		},
		{
			icon: aboutService.service5,
			title: "Hybrid Mobile Applications",
		},
		{
			icon: aboutService.service6,
			title: "Logo and Branding",
		},
		{
			icon: aboutService.service7,
			title: "ERP/CRM Develoment",
		},
		{
			icon: aboutService.service8,
			title: "Web Hosting",
		},
	];
	return (
		<>
			<Loading>
				<Header/>
				<div
					className="about_banner"
					style={{backgroundImage: `url(${images?.aboutBanner})`}}
				>
					<h1 className="text-white">About Us</h1>
				</div>
				<Container>
					<Row>
						<div className="d-flex align-items-center flex-wrap mt-5">
							<Col lg={6} md={12}>
								<div className="about_text">
									<h2>LogicStream</h2>
									<p>
										LogicStream is best known for its Creativity, Design, Website,
										Mobile and Software Development. With an innovative, creative
										and pragmatic approach, software development at Antim
										Technologies is no less than an exciting and engaging process.
										We have an in-built knack for delivering high-performance
										websites, mobile and software applications - of all size and
										complexities. In more simple terms, we make compelling
										products that scale to your business needs.
									</p>
									<div className="mt-4">
										<Button>Get In Touch</Button>
									</div>
								</div>
							</Col>
							<Col lg={6} md={12} className="pt-lg-0 pt-md-4 pt-4">
								<div className="d-flex justify-content-lg-end justify-content-center">
									<Image src={images?.about} width={"80%"} rounded/>
								</div>
							</Col>
						</div>
					</Row>
				</Container>

				<Container>
					<Row className="about_main ">
						<h3 className="text-center ">Our Services</h3>
						{aboutServiceData?.map((item) => {
							return (
								<Col lg={4}>
									<div className="service_data">
										<div className="icons_style">
											<Image src={item?.icon} width={"50px"}/>
										</div>
										<h3>{item.title}</h3>
									</div>
								</Col>
							);
						})}
					</Row>
				</Container>
				<div className="mt-5">
					<Footer/>
				</div>
			</Loading>
		</>
	);
};
export default About;
