import React from "react";
import "../HomeProduct/homeProduct.css";
import { images } from "../../assets";
import ProductCard from "../Common/ProductCard";

const HomeProduct = () => {
  const productData = [
    {
      image: images.product1,
      title: "iSportz",
      descripation:
        "iSportzAI Solutions combines innovation with practical application, delivering a robust platform......",
    },
    {
      image: images.product2,
      title: "Satellite Imagery",
      descripation:
        "The CROP damage assessment tool developed by Impetus AI Solution is an advanced technology......",
    },
    {
      image: images.product3,
      title: "Bank Statement Analyzer",
      descripation:
        "The Bank Statement Analyzer, developed by Impetus AI Solutions, is a cutting-edge software tool......",
    },
  ];
  return (
    <>
      <div className="bgcolor">
        <div className="product_head my-5">
          <p>Our Products</p>
          <h3>Products We Offer</h3>
        </div>

        <ProductCard productData={productData} />
      </div>
    </>
  );
};
export default HomeProduct;
