import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../component/Home/Home";
import About from "../component/About";
import Service from "../component/Service";
import CaseStudies from "../component/CaseStudies";
import ContactUs from "../component/ContactUs";

const AppRoute = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About/>} />
        <Route path="/service" element={<Service/>} />
        <Route path="/casestudies" element={<CaseStudies/>} />
        <Route path="/contactUs" element={<ContactUs/>} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoute;
