import React, {useEffect, useState} from "react";
import {projects, images} from "../../assets/index";
import {Col, Container, Image, Row} from "react-bootstrap";
import "../CaseStudies/caseStudies.css";
import Footer from "../Footer";
import Header from "../Header/Header";
import Loading from "../Common/Loading";

const CaseStudies = () => {
	const webData = [
		{
			webimage: projects?.web1,
			title: "Student OPT",
			weblink: "https://dev.studentopt.com/",
		},
		{
			webimage: projects?.web2,
			title: "CognoSpace Experiential Learning",
			weblink: "https://cognospace.in/",
		},
		{
			webimage: projects?.web3,
			title: "Starpowerz",
			weblink: "https://starpowerzdigiloans.com/",
		},
		{
			webimage: projects?.web4,
			title: "Ayaan Finserve",
			weblink: "https://afiloans.co.in/",
		},
		{
			webimage: projects?.web5,
			title: "Reditas Investment Platform",
			weblink: "https://afiloans.co.in/",
		},
	];

	return (
		<>
			<Loading>
				<Header/>
				<div
					className="caseStudiesBg"
					style={{backgroundImage: `url(${images?.caseStudiesBanner})`}}
				>
					<div className="project_text">
						<h3>Case Studies</h3>
					</div>
				</div>

				<Container>
					<div className="mt-5 caseStudiesProject">
						<Row>
							{webData.length > 0 &&
								webData?.map((item, index) => {
									return (
										<Col lg={4} md={12} key={index}>
											<div
												className="project_main"
												onClick={() => (window.location.href = item?.weblink)}
											>
												<Image src={item?.webimage} width={"100%"} height={'100%'}/>
											</div>
											<p className="text-center mt-2">{item?.title}</p>
										</Col>
									);
								})}
						</Row>
					</div>
				</Container>
				<div className="mt-5">
					<Footer/>
				</div>
			</Loading>
		</>
	);
};

export default CaseStudies;
