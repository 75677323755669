import React from "react";
import {images} from "../../assets";
import "../ContactUs/contact.css";
import {
	Button,
	Col,
	Container,
	FloatingLabel,
	Form,
	Row,
} from "react-bootstrap";
import Footer from "../Footer";
import Loading from "../Common/Loading";
import Header from "../Header/Header";

const ContactUs = () => {
	return (
		<>
			<Loading>
				<Header/>
				<div
					className="contact_banner"
					style={{backgroundImage: `url(${images?.contactBanner})`}}
				>
					<h3 className="text-white">Contact Us</h3>
				</div>
				<Container className="mt-5">
					<Row>
						<Col lg={6} md={12}>
							<div className="contact_text">
								<h4>
									<span>Contact us</span> to make requests for further
									information
								</h4>
								<p>We just need a couple of hours!</p>
								<p>
									Not more than 2 working days since receiving your issue
									ticket.
								</p>
							</div>
						</Col>
						<Col lg={6} md={12} className="pt-lg-0 pt-md-4 pt-4">
							<div className="contact_form">
								<Form>
									<Row>
										<Col lg={6} md={12}>
											<Form.Group>
												<FloatingLabel
													controlId="floatingInput"
													label="Name"
													className="mb-3"
												>
													<Form.Control
														required
														type="text"
														placeholder="Name"
													/>
												</FloatingLabel>
											</Form.Group>
										</Col>
										<Col lg={6} md={12}>
											<Form.Group>
												<FloatingLabel
													controlId="floatingInput"
													label="Email"
													className="mb-3"
												>
													<Form.Control
														required
														type="email"
														placeholder="Email"
													/>
												</FloatingLabel>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group>
												<FloatingLabel
													controlId="floatingInput"
													label="Subject *"
													className="mb-3"
												>
													<Form.Control
														required
														type="text"
														placeholder="Subject *"
													/>
												</FloatingLabel>
											</Form.Group>
										</Col>
									</Row>
									<Row>
										<Col>
											<Form.Group>
												<FloatingLabel
													controlId="floatingInput"
													label="Please describe what you need."
													className="mb-3"
												>
													<textarea rows={4} cols={20}></textarea>
												</FloatingLabel>
											</Form.Group>
										</Col>
									</Row>
									<div>
										<Button type="submit">Submit Message</Button>
									</div>
								</Form>
							</div>
						</Col>
					</Row>
				</Container>
				<div className="mt-5">
					<Footer/>
				</div>
			</Loading>
		</>
	);
};
export default ContactUs;
