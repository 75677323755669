export const images = {
  logo: require("./lightlogo.png"),
  logo_2: require("./lightlogo_light.png"),
  darkLogo: require("./impetus_logo-dark.png"),
  homeBanner: require("./home_banner_img.png"),
  heroInside: require("./hero-inside-image.png"),
  product1: require("./sportz_banner.png"),
  product2: require("./satellite_banner.png"),
  product3: require("./bank_statement_banner.png"),
  Service1: require("./Service-Artificial_intelligence.png"),
  Service2: require("./Service-web_develop.png"),
  Service3: require("./Service-app_development.png"),
  Service4: require("./Service-work_flow.png"),
  ServiceBanner: require("./service_banner.png"),
  serviceweb: require("./service-webdevelopment.jpg"),
  serviceApp: require("./service_AppDevelopment.png"),
  serviceSoftware: require("./service_software.jpg"),
  caseStudiesBanner: require("./case_studies_banner.png"),
  aboutBanner: require("./about_us_banner.png"),
  about: require("./about_bg.jpg"),
  contactBanner: require("./contact_us_banner.png"),
};

export const projects = {
  web1: require("./project_web1.jpg"),
  web2: require("./project_web2.jpg"),
  web3: require("./project_web3.jpg"),
  web4: require("./project_web4.jpg"),
  web5: require("./project_web5.jpg"),
};

export const aboutService = {
  service1: require("./about_Service_computer.png"),
  service2: require("./about_Service_application.png"),
  service3: require("./about_Service_application1.png"),
  service4: require("./about_Service_iphone.png"),
  service5: require("./about_Service_app-development.png"),
  service6: require("./about_Service_tools.png"),
  service7: require("./about_Service_erp.png"),
  service8: require("./about_Service_cloud-service.png"),
};
