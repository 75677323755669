import './App.css';
import AppRoute from './Route/AppRoute';

function App() {
  return (
   <AppRoute/>
  );
}

export default App;
