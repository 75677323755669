import React from "react";
import {images} from "../../assets";
import "../Service/service.css";
import {Col, Container, Image, Row, Tab, Tabs} from "react-bootstrap";
import Footer from "../Footer";
import Header from "../Header/Header";
import Loading from "../Common/Loading";

const Service = () => {
	return (
		<>
			<Loading>
				<Header/>
				<div
					className="service_banner"
					style={{backgroundImage: `url(${images?.ServiceBanner})`}}
				>
					<h2
						className="text-center d-flex justify-content-center align-items-center text-white fw-bold fs-1"
						style={{height: "350px"}}
					>
						Services
					</h2>
				</div>

				<Container>
					<div className="mt-5">
						<Tabs
							defaultActiveKey="WebDevelopment"
							id="uncontrolled-tab-example"
							className="mb-3 service_main"
						>
							<Tab eventKey="WebDevelopment" title="Web Development">
								<div className="mt-5 service_content">
									<Row>
										<Col lg={6} md={12}>
											<div className="">
												<p>
													Full-stack engineering using the latest industry
													technologies to upscale your business
												</p>
												<p>
													Drive your business with disruptive web solutions to
													boost your business and emerge victorious among
													competitors. We help you charm your potential customers
													and audience by developing lightning-fast web
													applications with trailblazing web interfaces developed
													with proven technologies.
												</p>
												<p>
													Full-stack engineering using the latest industry
													technologies to upscale your business. Drive your
													business with disruptive web solutions to boost your
													business and emerge victorious among competitors. We
													help you charm your potential customers and audience by
													developing lightning-fast web applications with
													trailblazing web interfaces developed with proven
													technologies. The modern web is the most versatile and
													rapidly evolving industry. So, if you want to harness
													the power of these modern frameworks by building
													progressive web applications, creating a responsive
													design, and improve the user experience then you must
													hire Sprint Technologies. We exceed our customer's
													expectations and develop web apps keeping the modern-era
													in our minds. In this mobile age, we must exceed users'
													expectations to convert them into loyal followers and
													regular buyers.
												</p>
											</div>
										</Col>
										<Col lg={6} md={12}>
											<div>
												<Image src={images?.serviceweb} width={"100%"}/>
											</div>
										</Col>
									</Row>
									<div className="mt-3">
										<p>
											We do in-depth research to provide the best services, and
											up-to-the-mark solutions to meet business needs and handle
											their most complex challenges. Our development is centred on
											the analysis, which helps us to understand the modern web
											concepts so that we can build custom and advanced solutions
											for prominent brands.
										</p>
										<p>
											Our dedicated team put their best foot forward to ensure
											that our web applications meet your business goals and
											provide a seamless user experience. Our developers closely
											work with UI and UX designers and fulfil the needs of
											experienced product strategists. We make sure that our end
											products prove to be game-changing, pioneering flawless
											development.
										</p>
									</div>
									<div className="mt-5">
										<Row>
											<Col lg={4} md={12}>
												<div>
													<h3>System Architecture</h3>
													<p>
														Our skilful developers help our clients to implement
														their dream projects precisely. Our experienced team
														members help you achieve your goal and provide you the
														best alternatives based on performance, security,
														cost, and market needs.
													</p>
												</div>
											</Col>
											<Col lg={4} md={12}>
												<div>
													<h3>Frontend Web Development</h3>
													<p>
														We develop cosmopolitan web applications to help
														businesses solve their complex challenges. We provide
														flexible, easy, flawless, and efficient solutions for
														all devices, browsers, and operating systems.
													</p>
												</div>
											</Col>
											<Col lg={4} md={12}>
												<div>
													<h3>Backend Development</h3>
													<p>
														Since the beginning of the development, our developers
														build custom solutions to meet the project's basic
														needs. From integrating with existing systems,
														development for middleware, or build from scratch
														solution, our seasoned developers have done it all.
													</p>
												</div>
											</Col>
										</Row>
									</div>
								</div>
							</Tab>
							<Tab eventKey="AppDevelopment" title="Application Development">
								<div className="service_content">
									<Row className="mt-5">
										<Col lg={6} md={12} className="">
											<div>
												<p>
													Accelerate your business growth by launching addictive
													mobile applications that deliver an enjoyable mobile
													experience developed with robust technology. The
													game-changing ideas by Sprint Technologies' developers
													will invent mobile applications your users will get
													addicted to. Our thoughtful development practices create
													experiences that benefit your users and customers.
												</p>
												<p>
													AnTim Technologies offer 360-degree services of
													application design, management, development, and
													integration services. We offer best-in-class mobile
													experiences for the esteem brands to help them transform
													their businesses into a well-organized digital
													enterprise. Our company leads the entire mobile app
													development process with original ideas, timely
													delivery, and quick support. Our experience enables us
													to solve any business challenges that a major industry
													has to offer.
												</p>
											</div>
										</Col>
										<Col lg={6} md={12}>
											<div>
												<Image src={images?.serviceApp} width={"90%"}/>
											</div>
										</Col>
									</Row>

									<div>
										<p>
											The knack of knowing all platforms help us acquire a
											profundity to comprehend scenarios tied to all phases of the
											development cycle. Ours thrive to innovate constantly
											empowers us to launch new features and highlights at regular
											intervals instead of every few months. Our visionary
											approach helps us create applications that are future-proof
											and flexible to adapt to the latest technologies.
										</p>
										<p>
											We, at Sprint Technologies, believe in teamwork, and
											therefore our developers join forces with creative designers
											and genius product strategists at every stage of
											development. As a result, our applications are not only
											precisely built but offer a unique experience that your
											users will turn to rigorously.
										</p>
										<p>Mobile technologies, we excel in:</p>
										<p>
											As per the market demands, our mobile applications
											developers build solutions that create a prolific brand
											identity of our customers and encourage business growth and
											expansion.
										</p>
									</div>

									<Row className="mt-5">
										<Col lg={4} md={12}>
											<div>
												<h3>iOS Development</h3>
												<p>
													We have expertise in iOS development to develop a
													revolutionary mobile application.
												</p>
											</div>
										</Col>
										<Col lg={4} md={12}>
											<div>
												<h3>Android App Development</h3>
												<p>
													With the latest technologies, we help brands build a
													strong presence on modern Android devices.
												</p>
											</div>
										</Col>
										<Col lg={4} md={12}>
											<div>
												<h3>Cross-platform App Development</h3>
												<p>
													Get robust mobile applications that run on various
													platforms to meet users' expectations without losing
													quality.
												</p>
											</div>
										</Col>
									</Row>
								</div>
							</Tab>
							<Tab eventKey="SoftwareDevelopment" title="Software Development">
								<div className="mt-5 service_content">
									<Row>
										<Col lg={6} md={12}>
											<div className="">
												<p>
													Innovate, enhance, develop, and integrate your business
													with the best software services by Sprint Technologies.
													We help you build high-quality software products to
													automate and simplify your practices as well as provide
													several professional bits of advice to boost your
													growth.
												</p>
												<p>
													We partner with entrepreneurs and business consultants
													to develop software for start-ups & various successful
													tech businesses. Leading companies from all over India
													believe us and trust in our vision to build their
													products effectively. We guarantee to provide you the
													most satisfactory product and deliver it within a
													dedicated timeline.
												</p>
												<p>
													Our services are tailored to meet the needs of each
													client. Our products will provide you a seamless and
													unique user experience that multiplies business growth
													as well as provide relevant, useful, and innovative
													solutions. We follow the following steps to conquer the
													software service industry which helps us monitor our
													successes and rectify our failures.
												</p>
											</div>
										</Col>
										<Col lg={6} md={12}>
											<div>
												<Image src={images?.serviceSoftware} width={"100%"}/>
											</div>
										</Col>
									</Row>
									<Row className="mt-5">
										<Col lg={6} md={12} className="mt-2">
											<div className="">
												<div>
													<h3>Conceptualization</h3>
													<p>
														We comprehend clients' problems and influence
														different methodologies to conceptualize the best
														solution for their business. The methods we use are:
													</p>
													<p>
														We take user interviews, analyse them, do surveys, and
														come to the best possible solutions.
													</p>
													<p>
														We even speak with top-in-line personalities who are
														responsible for the existence of the product and
														company.
													</p>
												</div>
												<div>
													<h3>Design the perfect model</h3>
													<p>
														To provide complex services, you need easy solutions.
														We, at Sprint Technologies, do exactly that. We design
														an effective model that allows the product or
														application to move faster in spite of doing all the
														complex procedures in the backend.
													</p>
												</div>
											</div>
										</Col>
										<Col lg={6} md={12} className="mt-3">
											<div>
												<h3>Develop</h3>
												<p>
													We structure adaptable and versatile designs keeping
													tech limitations in mind. Every interactivity is
													systematically planned so that our end clients don't
													face any trouble while using it to their advantage.
												</p>
											</div>
											<div>
												<h3>Growth analysis</h3>
												<p>
													Since the beginning of every project, we measure the end
													results with our goals and strategize around it. Growth
													strategies are designed to meet KPIs. We test, analyse,
													and implement the constant changes to build a perfect
													product that meets all business goals and solve users'
													problems as well.
												</p>
											</div>
										</Col>
									</Row>
								</div>
							</Tab>
						</Tabs>
					</div>
				</Container>
				<div className="mt-5">
					<Footer/>
				</div>
			</Loading>
		</>
	);
};
export default Service;
