import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { images } from "../../assets";
import "../Footer/footer.css";
const Footer = () => {
  return (
    <>
      <div className="footer_main">
        <Container>
          <Row>
            <Col lg={4} md={12}>
              <div className="logo">
                <Image src={images?.logo} width={"180px"} height={"70px"} />
              </div>
				<div className="mt-3">
					<h5>
						154-D Wing, Atlanta mall, opp. S.M.C.Community Hall, Mota Varachha, Surat, Gujarat 394101
					</h5>
					<p>
					<a href="tel:+918980146599" className="text-white text-decoration-none">+91 8980146599</a>
					</p>
					<p>
					<a href="mailto:info@logicstream.in"
					   className="text-white text-decoration-none">info@logicstream.in</a>
					</p>

				</div>
			</Col>
			  <Col lg={4} md={12} className="my-4">
				  <div className="d-flex flex-column  ">
                <div>
                  <h4>Quick Links</h4>
                </div>
                <div className="mt-4">
                  <p>Home</p>
                  <p>About Us</p>
                  <p>Products</p>
                  <p>Services</p>
                  <p>Case Studies</p>
                  <p>Contact Us</p>
                </div>
              </div>
            </Col>

            <Col>
              <div>
                <div>Follow Us</div>
                <div className="d-flex mt-5 gap-3">
                  <div className="social_icon">
                    <a>
                      <i class="fa-brands fa-instagram"></i>
                    </a>
                  </div>
                  <div className="social_icon">
                    <a>
                      <i class="fa-brands fa-facebook-f"></i>
                    </a>
                  </div>
                  <div className="social_icon">
                    <a>
                      <i class="fa-brands fa-linkedin-in"></i>
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default Footer;
