import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";

const Loading = ({children}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [children]);

  if (loading) {
    return (
      <div className="loader-container">
        <ThreeDots
          height="80"
          width="80"
          color="#805DCD"
          ariaLabel="loading"
          visible={true}
        />
      </div>
    );
  }
  return children;
};
export default Loading;
