import React from "react";
import { images } from "../../assets";
import "../HeroPreview/heroPreview.css";
import { Button, Col, Image, Row } from "react-bootstrap";

const HeroPreview = () => {
  return (
    <>
      <div
        className="bg_banner"
        style={{ backgroundImage: `url(${images?.homeBanner})` }}
      >
        <Row className="py-4">
            <Col lg={6} md={12} className="d-flex align-items-center">
              <div className="mx-5">
                <p className="fs-1 fw-bold">
                  Infuse New and Fresh Energy into Your Business with
                  <span style={{color:'#805DCD'}}> Impetus</span>
                </p>
                <p className="fs-4" style={{color:'#696969'}}>
                  Unlock unprecedented potential and drive growth. Experience
                  the transformative power of innovation with Impetus.
                </p>
                <Button className="btn-explore">Explore More</Button>
              </div>
            </Col>
            <Col lg={6} md={12} className="my-2">
              <Image src={images?.heroInside} width={"100%"} />
            </Col>
        </Row>
      </div>
    </>
  );
};
export default HeroPreview;
