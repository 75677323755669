import React from "react";
import Header from "../Header/Header";
import HeroPreview from "../HeroPreview";
import CompanyInfo from "../CompanyInfo";
import HomeProduct from "../HomeProduct";
import HomeServices from "../HomeServices";
import Footer from "../Footer";

const Home = () => {
  return (
    <>
      <Header />
      <HeroPreview />
      <CompanyInfo />
      <HomeProduct />
      <HomeServices />
      <Footer/>
    </>
  );
};

export default Home;
