import React from "react";
import "../HomeServices/homeService.css";
import { images } from "../../assets";
import { Col, Image, Row, Button } from "react-bootstrap";

const HomeServices = () => {
  const servicesData = [
    {
      icon: images.Service1,
      title: "Artificial Intelligence",
      decription:
        "Artificial intelligence is a futuristic technology responsible for the global transformation of the...",
    },
    {
      icon: images.Service2,
      title: "Web Development",
      decription:
        "At Impetus AI Solutions, our web development services encompass the creation of robust, scalable...",
    },
    {
      icon: images.Service3,
      title: "App Development",
      decription:
        "Mobile application development at Impetus AI Solutions focuses on creating software for...",
    },
    {
      icon: images.Service4,
      title: "Workflow Management",
      decription:
        "Impetus AI Solutions offers comprehensive workflow management services designed to optimize and...",
    },
  ];
  return (
    <>
      <div className="service_head my-5">
        <p>Our Services</p>
        <h3>Services We Offer</h3>
      </div>

      <div className="container mb-5">
        <Row className="g-4">
          {servicesData.map((service, index) => (
            <Col key={index} xs={12} sm={12} md={12} lg={6}>
              <div className="d-flex gap-2 main_services">
                <div>
                  <Image
                    src={service.icon}
                    alt={service.title}
                    className="mb-3"
                    width={60}
                    height={60}
                    fluid
                  />
                </div>
                <div className="service-text">
                  <h5 className="mb-2">{service.title}</h5>
                  <p className="">{service?.decription}</p>
                  <a href="#" className="">
                    Explore &rarr;
                  </a>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
};
export default HomeServices;
