import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import "../Header/header.css";
import { images } from "../../assets";
import { Image } from "react-bootstrap";

const Header = () => {
  const [activeTab, setActiveTab] = useState("");

  const handleSelect = (eventKey) => {
    setActiveTab(eventKey);
  };

  return (
    <>
      <Navbar expand="lg" className="bg-dark px-2" sticky="top" variant="dark">
        <Container fluid>
          <Navbar.Brand href="/">
            <Image src={images?.logo_2} width={"150px"} height={"50px"} />
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Offcanvas id={`offcanvasNavbar-expand-md`} placement="end">
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-md`}>
                <Image src={images?.logo} width={"140px"} height={"60px"} />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Nav
                className="justify-content-end flex-grow-1"
                activeKey={activeTab}
                onSelect={handleSelect}
              >
                <Nav.Link href="/" eventKey="#home">
                  Home
                </Nav.Link>
                <Nav.Link href="/about-us" eventKey="#about">
                  About Us
                </Nav.Link>
                {/* <Nav.Link href="#link" eventKey="#products">
                  Products
                </Nav.Link> */}
                <Nav.Link href="/service" eventKey="#services">
                  Services
                </Nav.Link>
                <Nav.Link href="/casestudies" eventKey="#case-studies">
                  Case Studies
                </Nav.Link>
                <Nav.Link href="/contactUs" eventKey="#contact">
                  Contact Us
                </Nav.Link>
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
