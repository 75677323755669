import React from "react";
import { Button, Col, Image, Row } from "react-bootstrap";
import "../ProductCard/productCard.css";

const ProductCard = ({ productData }) => {
  return (
    <>
      <Row>
        <div className="d-flex justify-content-center gap-5 my-4 flex-wrap">
          {productData?.map((item) => {
            return (
              <Col lg={2} md={12}>
                <div className="card_main">
                  <div className="product_image">
                    <Image
                      className=""
                      width={"100%"}
                      rounded
                      src={item.image}
                    />
                  </div>
                  <div className="product-titles">
                    <h5>{item?.title}</h5>
                    <p>{item?.descripation}</p>
                    <div className="px-3">
                      <Button className="btn-explore">Explore More</Button>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}
        </div>
      </Row>
    </>
  );
};
export default ProductCard;
